import { t } from "i18n-js";
import {
  isCommunityAdmin,
  isCommunityModerator,
} from "@/react/helpers/communityMemberHelpers";
import { usePlanStatus } from "@/react/hooks/usePlanStatus";
import { useApiUsage } from "@circle-react/components/SettingsApp/DevelopersOverview/useApiUsage";
import { usePunditUserContext } from "@circle-react/contexts";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { useIsMarketingHubEmailBroadcastsEnabled } from "@circle-react/hooks/useIsMarketingHubEmailBroadcastsEnabled";
import { useIsWorkflowsEnabled } from "@circle-react-shared/Workflows";
import type { SidebarLink } from "./types";

export const BACK_TO_COMMUNITY_PATH_KEY = "back-to-community";

export const useCollapsedSidebarLinksV3 = (): SidebarLink[] => {
  const { currentCommunityMember, currentCommunity } = usePunditUserContext();
  const isWorkflowsEnabled = useIsWorkflowsEnabled();
  const isHomePageEnabled = Boolean(currentCommunity?.home_page_enabled);
  const { isCommunityOnPlus } = usePlanStatus();
  const { isApiAvailable } = useApiUsage();

  const isAffiliatesEnabled = Boolean(
    currentCommunity.affiliates_feature_flag_enabled &&
      isCommunityAdmin(currentCommunityMember),
  );

  const hasEmail = useIsMarketingHubEmailBroadcastsEnabled();

  return [
    {
      pathKey: BACK_TO_COMMUNITY_PATH_KEY,
      label: t("settings_nav_v3.back_to_community.label"),
      icon: "20-back-to-community",
      path: isHomePageEnabled ? "/feed" : "/",
      enabled:
        isCommunityAdmin(currentCommunityMember) ||
        isCommunityModerator(currentCommunityMember),
    },
    {
      pathKey: "members",
      label: t("settings_nav_v3.members.label"),
      icon: "20-members",
      path: settingsNavPathV3.members.manage,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "content",
      label: t("settings_nav_v3.content.label"),
      icon: "20-content",
      path: settingsNavPathV3.content.posts,
      enabled:
        isCommunityAdmin(currentCommunityMember) ||
        isCommunityModerator(currentCommunityMember),
    },
    {
      pathKey: "email",
      label: t("settings_nav_v3.emails.label"),
      icon: "20-send",
      path: settingsNavPathV3.email.broadcast,
      enabled: hasEmail && isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "workflows",
      label: t("settings_nav_v3.workflows.label"),
      icon: "20-workflows",
      path: settingsNavPathV3.workflows.all,
      enabled: isCommunityAdmin(currentCommunityMember) && isWorkflowsEnabled,
    },
    {
      pathKey: "analytics_v2",
      label: t("settings_nav_v3.analytics.label"),
      icon: "20-analytics",
      path: settingsNavPathV3.analytics_v2.index,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "paywalls",
      label: t("settings_nav_v3.payments.label"),
      icon: "20-payments",
      path: settingsNavPathV3.paywalls.index,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "affiliates",
      label: t("settings_nav_v3.paywalls_affiliates.label"),
      icon: "20-paywalls-affiliates",
      path: settingsNavPathV3.affiliates.settings,
      enabled: isAffiliatesEnabled,
    },
    {
      pathKey: "plans",
      label: isCommunityOnPlus
        ? t("settings_nav_v3.plans.plus_label")
        : t("settings_nav_v3.plans.label"),
      icon: "20-plans",
      path: settingsNavPathV3.plans.general,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "site",
      label: t("settings_nav_v3.site.label"),
      icon: "20-site",
      path: settingsNavPathV3.site.index,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "developers",
      label: t("settings_nav_v3.developers.title"),
      icon: "20-stack-dev",
      path: isApiAvailable
        ? settingsNavPathV3.developers.overview
        : settingsNavPathV3.developers.api,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "settings",
      label: t("settings_nav_v3.settings.label"),
      icon: "20-nav-settings",
      path: settingsNavPathV3.settings.general,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
  ];
};
